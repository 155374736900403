<template>
  <v-app>
    <!-- <v-app-bar v-if="appFrame" app color="#1e2428" dark> -->
    <v-app-bar v-if="appFrame" app color="transparent" elevation="0" dark>
      <v-text-field
        v-if="$store.state.visibleSearch"
        v-model="search"
        rounded
        filled
      light
        hide-details
        dense
        class="mx-6"
        :placeholder="`Buscar ${$store.state.searchTerm}`"
        prepend-inner-icon="fa-search"
        style="border-radius: 10px !important"
      >
        <template v-slot:prepend-inner>
          <v-icon small class="mt-1 mr-2">fa-search</v-icon>
        </template>
      </v-text-field>

      <v-spacer></v-spacer>

      <div class="user-descrip" style="color: #1e2428;" v-if="user">
        <div>
          <v-card-title
            class="font-weight-bold"
            style="letter-spacing: 0.125em; line-height: 1.6rem"
            >{{ user.name }}</v-card-title
          >
          <v-card-subtitle
            class="font-weight-light"
            style="margin-top: -22px; letter-spacing: 0.125em"
            >Administrador</v-card-subtitle
          >
        </div>
        <img src="@/assets/user-01.svg" style="object-fit: cover;" class="photo" alt="" srcset="" />
      </div>
    </v-app-bar>
    <Snackbar style="z-index: 1000; width: 100%; position: fixed" />
    <v-main >
      <router-view  class="pl-6" />
    </v-main>
    <v-navigation-drawer permanent  v-if="navBar" class="navigation" app style="border-radius: 20px !important;" color="primary" dark>
      <v-img src="@/assets/SVG/Logo.svg" width="50%" class="ma-auto my-6" />

      <v-list nav dense dark>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
          class="pl-5"
          color="#fff"
        >
          <v-list-item-icon>
            <v-icon small>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-4">
          <v-btn
            block
            color="#000"
            style="border-radius: 10px"
            @click="logOut"
          >
            <v-icon small class="mr-2">fa-sign-out-alt</v-icon>

            Cerrar Sesión</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import Snackbar from "@/components/snackbar";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    appFrame: false,
    navBar: false,
    user: null,

    baseRoute: {
      sudo: "/salesCoupons",
      admin: "/salesCoupons",
    },
  }),
  computed: {
    ...mapState(["role", "selectedBusiness", "search"]),
    items() {
      if (this.user?.type == "sudo" && this.user?.role == "sudo") {
        return [
          {
            title: "Reporte de cupones",
            icon: "fa-regular fa-file-lines",
            link: "/salesCoupons",
          },
          { title: "Cupones", icon: "fa-solid fa-ticket", link: "/coupons" },
           {
       
            title: "Ciudades",
            icon: "fa-solid  fa-city",
            link: "/cities",
          },
           {
       
            title: "Categorias",
            icon: "fa-solid  fa-tags",
            link: "/categories",
          },
          { title: "Secciones", icon: "fa-solid fa-stream", link: "/sections" },
          { title: "Comercios", icon: "fa-solid fa-building", link: "/business" },
          { title: "Comercios para Validar", icon: "fa-solid fa-building-circle-check", link: "/businessPending" },
          { title: "Usuarios", icon: "fa-solid fa-user-group", link: "/users" },
          { title: "Usuarios para Validar", icon: "fa-solid fa-user-check", link: "/usersPending" },
          {
            title: "Usuarios Admin",
            icon: "fa-solid fa-users",
            link: "/usersAdmin",
          },
          // {
          //   title: "Reporteria",
          //   icon: "fas fa-file",
          //   link: "/reporteria",
          // },
         
        ];
      } else {
        return [
           {
            title: "Reporte de cupones",
            icon: "fa-regular fa-file-lines",
            link: "/salesCoupons",
          },
          {
            title: "Redimir",

            icon: "fa-regular fa-file-lines",
            link: "/redemption",
          },
        ];
      }
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
  },
  watch: {
    $route(e) {
      this.appFrame = e.meta.appFrame;
      this.navBar = e.meta.navBar;
      // this.titlepage(e.meta.titlepage);
      if (e.meta.allowedRoles && !e.meta.allowedRoles.includes(this.role)) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    ...mapActions(["titlepage", "setUser", "Alert_", "setUserValidity_"]),
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.$router.push({
              path: "/",
            });
            this.selectedBusiness_l = null;
            this.addBusiness(null);
          });
      }
    },
  },
  mounted() {
    this.appFrame = this.$route.meta.appFrame;
    this.navBar = this.$route.meta.navBar;

    fb.auth().onAuthStateChanged((authUser) => {
      if (this.$route.meta.basePath) {
        if (authUser) {
          this.$binding(
            "user",
            db.collection(`appUsers`).doc(authUser.uid)
          ).then((user) => {
            this.user = user;
            if (!user.type || user.active == false) {
              console.debug("no entra");
              this.setUserValidity_(false);
              this.Alert_({
                text: "Este perfíl de usuario no tiene permitido ingresar a la plataforma",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
              this.logOut();
            } else {
              this.setUser(user);

              console.debug(user);
              if (this.$router.currentRoute.name === "login") {
                this.$router.push({
                  path: this.baseRoute[user.type],
                });
              } else if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.type)
              ) {
                //validating user permissions for this route
                this.$router.push({ path: this.baseRoute[user.type] });
              }
            }
          });
        } else {
          this.setUser(null);
          this.loading = false;
          var rutes = [
            "cities",
            "business",
            "coupons",
            "users",
            "salesCoupons",
            "redemption",
            "usersAdmin",
            "home",
          ];
          if (rutes.includes(this.$route.name) && !this.user)
            this.$router.push({ path: "/" });
        }
      }
    });
  },
};
</script>

<style>

.navigation{
  margin: 10px;
  height:calc(100vh - 20px) !important;

}
</style>